<template>
    <div>
        <h1>404</h1>
        <p>Page Not Found</p>
    </div>
</template>

<script>
export default {
name: 'PageNotFound',

    data () {
        return {

        }
    },
}

</script>

<style scoped>
</style>
